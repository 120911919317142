import { getConfig } from "../config/config";
import axios from "axios"
import { authHeader, history } from "../logic";
export const adminService = {
  validateLogin,
  adminlogin,
  relogin,
  logout,
  adminregister,
  lostpassword,
  resetpassword,
  disableUsers,
  enableUsers,
  recoverPassword,
  adminChangePassword,
  adminAddMarket,
  adminAddHalal,
  adminUpdateHalal,
  adminUpdateMarket,
  adminUpdateMarketCategory,
  adminUpdateMarketNews,
  updateAdmin,
  modifyTargetCommission,
  AddPaymentOptions,
  UpdatePaymentOptions,
  EditDues,
  adminUpdateHalalNews,
  regularSavingsTransactionsAdmin,
  saveLoanTransactionsAdmin,
  targetTransactionsAdmin,
  adminUpdateHalalCategory,
  admindeleteCategory,
  admindeleteHalaiCategory,
  admindeleteMarketNews,
  deleteTargetCommission,
  admindeleteHalalNews,
  adminAddMarketCategory,
  adminAddHalalNews,
  adminAddMarketNews,
  addAdmin,
  reversePosting,
  makeWithdrawal,
  disableAdmin,
  enableAdmin,
  deleteUser,
  adminAddHalalCategory,
  addTargetCommission,
  addDues,
  adminApproveLoanz,
  disburstLoan,
  getAllUsersAutoComplete,
  saveMultipleAcct,
  saveMultipleDues,
  addNewBank,
  updateBank,
  createUserLoan,
  update,
  delete: _delete,
  addUser,
  createTargetAcct,
  approveOrder,
  declineOrder,
  deliverOrder,
  addProductCategory,
  deleteProductCategory,
  addPrice,
  addProduct,
  deleteProduct,
  editProduct,
  addCart,
  createOrder,
  addBusinessPrice,
  approveBusinessRequest,
  cancelBusinessRequest,
  deleteActivities,
  disburseBusinessLoan,
  accountSettings,
  createRole,
  updateRole,
  activateOnline,
  activateUser
};

function validateLogin(email, password) {
	const requestOptions = {
	  method: "POST",
	  headers: { "Content-Type": "application/json", "Accept": "application/json" },
	  body: JSON.stringify({ email, password }),
	};
	return fetch(getConfig("validateLogin"), requestOptions).then(handleResponse)
  }

function adminlogin(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", "Accept": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(getConfig("adminlogin"), requestOptions)
    .then(handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("admin", JSON.stringify(user));
      localStorage.setItem("email", user.email);
      localStorage.setItem("name", user.name);
      localStorage.setItem("profile_pic", user.profile_pic);

      return user;
    });
}
function adminregister(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(getConfig("adminsignup"), requestOptions).then(handleResponse);
}
function getAllUsersAutoComplete(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(getConfig("getAllUsersAutoComplete"), requestOptions).then(handleResponse);
}

function createUserLoan(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(getConfig("createUserLoan"), requestOptions).then(handleResponse);
}

// make withdrawal 
function makeWithdrawal(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(getConfig("makeWithdrawal"), requestOptions).then(
    handleResponse
  );
}

function createRole(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };
  return fetch(getConfig("createRole"), requestOptions).then(handleResponse);
}

function updateRole(user) {
  // console.log(user)
  let token = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };
  return fetch(getConfig("updateRole")+user.id+'?token='+token.token, requestOptions).then(handleResponse);
}

function addUser(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(getConfig("addUser"), requestOptions).then(handleResponse);
}

function createTargetAcct(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(getConfig("createTargetAcct"), requestOptions).then(handleResponse);
}

function addBusinessPrice(user) {
  let token = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };
  return fetch(getConfig("addBusinessPrice")+user.id+'?token='+token.token, requestOptions).then(handleResponse);
}

function cancelBusinessRequest(id) {
  let token = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    // body: JSON.stringify(),
  };
  return fetch(getConfig("cancelBusinessRequest")+id+'?token='+token.token, requestOptions).then(handleResponse);
}

function approveBusinessRequest(id) {
  let token = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    // body: JSON.stringify(user),
  };
  return fetch(getConfig("approveBusinessRequest")+id+'?token='+token.token, requestOptions).then(handleResponse);
}

function addProductCategory(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(getConfig("addProductCategory"), requestOptions).then(handleResponse);
}

function createOrder(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(getConfig("createOrder"), requestOptions).then(handleResponse);
}

function addCart(data) {
  let token = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({data}),
  };

  return fetch(getConfig("addCart")+data.id+"?token="+token.token, requestOptions).then(handleResponse);
}

function accountSettings(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(getConfig("accountSettings"), requestOptions).then(handleResponse);
}

function addPrice(price, id) {
  let token = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(price),
  };
  return fetch(getConfig("addPrice")+id+"?token="+token.token, requestOptions).then(handleResponse);
}

function addProduct(fd) {
  const headers =  { ...authHeader(), "Content-Type": "application/json" }
  return axios.post(getConfig("addProduct"), fd, {headers}).then((res) => {
    if(res.data === "Unauthorized"){
        history.push("/sign-in");
    }
    return res.data
  })
}

function deleteProduct(id) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${getConfig("deleteProduct")}/${id}`, requestOptions).then(handleResponse)
}

function editProduct(fd) {
  const headers =  { ...authHeader(), "Content-Type": "application/json" }
  return axios.post(`${getConfig("editProduct")}/${fd.id}`, fd.values, {headers}).then((res) => {
    if(res.data === "Unauthorized"){
      history.push("/sign-in");
    }
    return res.data
  })
}

function deleteProductCategory(id) {
  let token = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(getConfig("deleteProductCategory")+id+"?token="+token.token, requestOptions).then(handleResponse);
}

function approveOrder(id) {
  let token = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    // body: JSON.stringify(user),
  };
  return fetch(getConfig("approveOrder")+id+"?token="+token.token, requestOptions).then(handleResponse);
}

function declineOrder(id) {
  let token = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    // body: JSON.stringify(user),
  };
  return fetch(getConfig("declineOrder")+id+"?token="+token.token, requestOptions).then(handleResponse);
}

function deliverOrder(id) {
  let token = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    // body: JSON.stringify(user),
  };
   return fetch(getConfig("deliverOrder")+id+"?token="+token.token, requestOptions).then(handleResponse);
}

function saveMultipleDues(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(getConfig("saveMultipleDues"), requestOptions).then(handleResponse);
}
function saveMultipleAcct(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(getConfig("saveMultipleAcct"), requestOptions).then(handleResponse);
}

function addNewBank(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(getConfig("addNewBank"), requestOptions).then(handleResponse);
}

function updateBank(user) {
  let token = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };
  return fetch(getConfig("updateBank")+user.bank_id +"?token="+token.token, requestOptions).then(handleResponse);
}

// Add Halal
function adminAddHalal(fd) {
  const headers =  { ...authHeader(), "Content-Type": "application/json" }
  return axios.post(getConfig("adminAddHalal"), fd, {headers}).then((res) => {
    if(res.data === "Unauthorized"){
        history.push("/sign-in");
    }
    return res.data
  })
}

// Update Halal
function adminUpdateHalal(fd, id) {
  let user = JSON.parse(localStorage.getItem('admin'));
  const headers =  { ...authHeader(), "Content-Type": "application/json" }
  return axios.post(getConfig("adminUpdateHalal")+id +"?token="+user.token, fd, {headers}).then((res) => {
    if(res.data === "Unauthorized"){
          history.push("/sign-in");
    }
    return res.data
  })
}

// Add market
function adminAddMarket(fd) {
  const headers =  { ...authHeader(), "Content-Type": "application/json" }
  return axios.post(getConfig("adminAddMarket"), fd, {headers}).then((res) => {
    if(res.data === "Unauthorized"){
          history.push("/sign-in");
    }
    return res.data
  })
}

// Update Market
function adminUpdateMarket(fd, id) {
  let user = JSON.parse(localStorage.getItem('admin'));
  const headers =  { ...authHeader(), "Content-Type": "application/json" }
  return axios.post(getConfig("adminUpdateMarket")+id +"?token="+user.token, fd, {headers}).then((res) => {
    if(res.data === "Unauthorized"){
        history.push("/sign-in");
    }
    return res.data
  })
}

// Approve Loan 
function adminApproveLoanz(id) {
  let user = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    // body: JSON.stringify(data),
  };
  return fetch(getConfig("adminApproveLoanz")+id +"?token="+user.token, requestOptions).then(
    handleResponse
  );
}

function deleteActivities(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(getConfig("deleteActivities"), requestOptions).then(
    handleResponse
  );
}

// Disburst Loan 
function disburstLoan(data) {
  let user = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(getConfig("disburstLoan")+data.id +"?token="+user.token, requestOptions).then(
    handleResponse
  );
}

// disburse Business Loan 
function disburseBusinessLoan(data) {
  let user = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  // console.log(data)
  return fetch(getConfig("disburseBusinessLoan")+data.id +"?token="+user.token, requestOptions).then(
    handleResponse
  );
}

// Update market category
function adminUpdateMarketCategory(data) {
  let user = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(getConfig("updateMarketCategory")+data.id+"?token="+user.token, requestOptions).then(
    handleResponse
  );
}

function adminUpdateMarketNews(data) {
  let user = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(getConfig("updateMarketNews")+data.id+"?token="+user.token, requestOptions).then(
    handleResponse
  );
}

function updateAdmin(data) {
  let user = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(getConfig("updateAdmin")+data.id+"?token="+user.token, requestOptions).then(
    handleResponse
  );
}

function disableAdmin(id) {
  let user = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    // body: JSON.stringify(id),
  };
  return fetch(getConfig("disableAdmin")+id+"?token="+user.token, requestOptions).then(
    handleResponse
  );
}

function enableAdmin(id) {
  let user = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    // body: JSON.stringify(data),
  };
  return fetch(getConfig("enableAdmin")+id+"?token="+user.token, requestOptions).then(
    handleResponse
  );
}

function deleteUser(id) {
  let user = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    // body: JSON.stringify(data),
  };
  return fetch(getConfig("deleteUser")+id+"?token="+user.token, requestOptions).then(
    handleResponse
  );
}

function modifyTargetCommission(data) {
  let user = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(getConfig("modifyTargetCommission")+data.id+"?token="+user.token, requestOptions).then(
    handleResponse
  );
}

function AddPaymentOptions(data) {
  let user = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(getConfig("AddPaymentOptions")+0+"?token="+user.token, requestOptions).then(
    handleResponse
  );
}

function UpdatePaymentOptions(data) {
  let user = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(getConfig("UpdatePaymentOptions")+data.id+"?token="+user.token, requestOptions).then(
    handleResponse
  );
}

function EditDues(data) {
  let user = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(getConfig("EditDues")+data.id+"?token="+user.token, requestOptions).then(
    handleResponse
  );
}

function regularSavingsTransactionsAdmin(data) {
  let user = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(getConfig("regularSavingsTransactionsAdmin")+data.id+"?token="+user.token, requestOptions).then(
    handleResponse
  );
}

function saveLoanTransactionsAdmin(data) {
  let user = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(getConfig("saveLoanTransactionsAdmin")+data.id+"?token="+user.token, requestOptions).then(
    handleResponse
  );
}

function targetTransactionsAdmin(data) {
  let user = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(getConfig("targetTransactionsAdmin")+data.id+"?token="+user.token, requestOptions).then(
    handleResponse
  );
}

function adminUpdateHalalNews(data) {
  let user = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(getConfig("updateHalalNews")+data.id+"?token="+user.token, requestOptions).then(
    handleResponse
  );
}

function adminUpdateHalalCategory(data) {
  let user = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(getConfig("updateHalalCategory")+data.id+"?token="+user.token, requestOptions).then(
    handleResponse
  );
}

function admindeleteCategory(id) {
  let user = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    // body: JSON.stringify(data),
  };
  return fetch(getConfig("deleteCategory")+id+"?token="+user.token, requestOptions).then(
    handleResponse
  );
}

function disableUsers(id) {
  let user = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    // body: JSON.stringify(data),
  };
  return fetch(getConfig("disableUsers")+id+"?token="+user.token, requestOptions).then(
    handleResponse
  );
}

function enableUsers(id) {
  let user = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    // body: JSON.stringify(data),
  };
  return fetch(getConfig("enableUsers")+id+"?token="+user.token, requestOptions).then(
    handleResponse
  );
}

function admindeleteHalaiCategory(id) {
  let user = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    // body: JSON.stringify(data),
  };
  return fetch(getConfig("deleteHalaiCategory")+id+"?token="+user.token, requestOptions).then(
    handleResponse
  );
}

function admindeleteMarketNews(id) {
  let user = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    // body: JSON.stringify(data),
  };
  return fetch(getConfig("deleteMarketNews")+id+"?token="+user.token, requestOptions).then(
    handleResponse
  );
}
function deleteTargetCommission(id) {
  let user = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    // body: JSON.stringify(data),
  };
  return fetch(getConfig("deleteTargetCommission")+id+"?token="+user.token, requestOptions).then(
    handleResponse
  );
}

function admindeleteHalalNews(id) {
  let user = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    // body: JSON.stringify(data),
  };
  return fetch(getConfig("deleteHalaiNews")+id+"?token="+user.token, requestOptions).then(
    handleResponse
  );
}

// Admin add Market news
function adminAddMarketNews(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(getConfig("addMarketNews"), requestOptions).then(
    handleResponse
  );
}

function addAdmin(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(getConfig("addAdmin"), requestOptions).then(
    handleResponse
  );
}

function reversePostingx(data) {
  console.log(data)
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(getConfig(`reversePosting/${data}`), requestOptions).then(
    handleResponse
  );
}

function reversePosting(id) {
  console.log(id)
  let user = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    // body: JSON.stringify(data),
  };
  return fetch(getConfig("reversePosting")+id+"?token="+user.token, requestOptions).then(
    handleResponse
  );
}

function addTargetCommission(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(getConfig("addTargetCommission"), requestOptions).then(
    handleResponse
  );
}
function addDues(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(getConfig("addDues"), requestOptions).then(
    handleResponse
  );
}
// Admin add Halal category
function adminAddHalalNews(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(getConfig("addHalalNews"), requestOptions).then(
    handleResponse
  );
}

// Admin add Market category
function adminAddMarketCategory(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(getConfig("addMarketCategory"), requestOptions).then(
    handleResponse
  );
}
// Admin add halal category
function adminAddHalalCategory(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(getConfig("addHalalCategory"), requestOptions).then(
    handleResponse
  );
}

function activateOnline(data) {
  let user = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(getConfig("activateOnline")+data.id+"?token="+user.token, requestOptions).then(
    handleResponse
  );
}

function activateUser(id) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(getConfig("activateUser")+id, requestOptions).then(handleResponse);
}

function logout() {
  localStorage.removeItem("user");
  localStorage.removeItem("name");
  history.push('/sign-in')
}

function relogin(email) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(email),
  };

  return fetch(getConfig("relogin"), requestOptions)
    .then(handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("user", JSON.stringify(user));

      return user;
    });
}

function register(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(getConfig("signup"), requestOptions).then(handleResponse);
}

function lostpassword(email) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: email,
  };

  return fetch(getConfig("recoverpass"), requestOptions).then(handleResponse);
}

function resetpassword(user) {
  const requestOptions = {
    method: "POST",
    headers: {...authHeader, "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(getConfig("resetPass"), requestOptions).then(handleResponse);
}

function recoverPassword(user) {
  const requestOptions = {
    method: "POST",
    headers: {...authHeader, "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(getConfig("recoverPassword"), requestOptions).then(handleResponse);
}

function adminChangePassword(user) {
  const requestOptions = {
    method: "POST",
    headers: {...authHeader, "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(getConfig("adminChangePassword"), requestOptions).then(handleResponse);
}

function update(user) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(`{config.apiUrl}/users/${user.id}`, requestOptions).then(
    handleResponse
  );
}

function _delete(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(`{config.apiUrl}/users/${id}`, requestOptions).then(
    handleResponse
  );
}

function handleResponse(response) {
  return response.text().then((text) => {
    let data = "";
    try {
      data = text && JSON.parse(text);
    } catch (error1) {
      // ...
    }
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      if (error === "Unauthorized") {
        history.push("/sign-in");
      }
      return Promise.reject(error);
    }

    return data;
  });
}
