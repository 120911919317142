import React, { useState, Component } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid, CircularProgress, TextField, DialogActions, CardContent, Divider, DialogContent, DialogTitle, Dialog, } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { authHeader } from 'redux/logic';
import { getConfig, setLastUrl } from 'redux/config/config';
import { adminActions } from 'redux/action';
import CategoryTable from './components/ProductTable/CategoryTable';


class ProductCategory extends Component{
  constructor(props){
    super(props)
    setLastUrl()
    this.state={
      data:{
        category_name : "",
      },
        users: [],
        loading:true,
        all: [],
        search: "",
        open:false,
        show:false
    }
    this.fetchOrders = this.fetchOrders.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.searchChange = this.searchChange.bind(this);
    this.fetch_next_page = this.fetch_next_page.bind(this);
    this.fetch_page = this.fetch_page.bind(this);
    this.fetch_prev_page = this.fetch_prev_page.bind(this);

  }

componentDidMount(){
  this.fetchOrders()
}

handleOpen = () =>{
  this.setState({show: true})
}

handleClose = () =>{
  this.setState({show: false})
}

handleSubmit =()=>{
  const {data} = this.state
  if(data.category_name ){
    this.props.addProductCategory(data)
  }
}

fetchOrders = () =>{
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body:JSON.stringify({search_term:this.state.search})
  };
  fetch(getConfig('allProductCategory'), requestOptions)
    .then(async response => {
    const data = await response.json();
    if (!response.ok) {
        this.setState({loading:false });
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }
    this.setState({users: data.data, all:data, loading:false});
  })
  .catch(error => {
    if (error === "Unauthorized") {
      this.props.logout();
    }
    this.setState({loading:false });
    console.error('There was an error!', error);
  });
}

handleChange =(e)=>{
  e.preventDefault()
  const {name, value} = e.target
  const {data} = this.state
  this.setState({data:{...data, [name]:value }}) 
}

searchChange(event) {
  const { value } = event.target;
  this.setState({  search: value, loading:true},()=>{ this.fetchOrders() });
}

fetch_next_page = ()=>{
  const {all} = this.state
  this.setState({ loading: true});
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  fetch(all.next_page_url, requestOptions).then(async (response) =>{
    const data =await response.json();
    this.setState({ loading: false, users:data.data, all:data });
  }).catch(error=>{
    if (error === "Unauthorized") {
      this.props.logout();
    }
  })
}

fetch_prev_page = ()=>{
  const {all} = this.state
  this.setState({ loading: true});
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  fetch(all.prev_page_url, requestOptions).then(async (response) =>{
    const data =await response.json();
    this.setState({ loading: false, users:data.data, all:data });
  }).catch(error=>{
    if (error === "Unauthorized") {
      this.props.logout();
    }
  })
}

fetch_page = (index)=>{
  const {all} = this.state
  this.setState({ loading: true});
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  fetch(all.path+"?page="+index, requestOptions).then(async (response) =>{
    const data =await response.json();
    this.setState({ loading: false, users:data.data, all:data });
  }).catch(error=>{
    if (error === "Unauthorized") {
      this.props.logout();
    }
  })
}

render(){
  let {theme} = this.props
  const {users, loading, search, open, show, data, all} = this.state
  return (
    <div style={{padding: theme.spacing(3)}}>
      <div style={{height: '42px' ,alignItems: 'center', marginTop: theme.spacing(1)}}>
        <TextField
          // style={{}}
          label="search"
          margin="dense"
          variant="outlined"
          value={search}
          onChange={this.searchChange}
          style={{marginRight: theme.spacing(1), width:"50%"}}
          placeholder="Search Category"
        />
        <div style={{float:'right'}}>
          <div className="row">
            <span className="spacer" />
            <Button color="primary" onClick={this.handleOpen} variant="contained"> Add New Category</Button>
          </div>
        </div>
      </div>
      <div style={{marginTop: theme.spacing(2)}}>
        <CategoryTable users={users} pagination={all} fetch_page={this.fetch_page} fetch_next_page={this.fetch_next_page} fetch_prev_page={this.fetch_prev_page} loading={loading} />
      </div>
    {/* Modal */}
    < Dialog
      open={show}
      fullWidth={true}
      maxWidth = {'xs'}
      keepMounted
      // value=""
      onClose={this.handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      <DialogTitle  id="alert-dialog-slide-title">Add Category</DialogTitle>  
      <Divider />     
      <DialogContent>
        <CardContent className="content">
          <form  noValidate autoComplete="off">
            <Grid>
              <Grid>
                <TextField
                  fullWidth
                  label="Category Name"
                  placeholder="Category Name"
                  margin="dense"
                  name="category_name"
                  onChange={this.handleChange}
                  value={data.category_name}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </form>
        </CardContent>
        <Divider /> 
        <DialogActions>
            <Grid item md={10} xs={10}>
                {this.props.savings && <CircularProgress />}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{marginLeft:8}}
                  onClick={this.handleSubmit}>
                  Submit
                </Button>
            </Grid> 
              <Button onClick={this.handleClose} 
                      variant="contained"
                      style={{color:'white', marginRight:8, backgroundColor:'red'}}>
                Cancel
              </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
    {/* Modal */}
    </div>
    );
  };
}

function mapState(state) {
  const { savings } = state.savings;
  return { savings };
}
const actionCreators = {
  addProductCategory: adminActions.addProductCategory,
  logout: adminActions.logout,
};

export default withStyles({}, { withTheme: true })(
  withRouter(connect(mapState,  actionCreators)(ProductCategory))
);