import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { adminActions } from "../../../redux/action";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { getConfig, setLastUrl} from '../../../redux/config/config'
import { authHeader} from '../../../redux/logic';
import { Grid, TextField, MenuItem } from '@material-ui/core';
import {UsersTable } from '../components/Savings';


class WalletTabTab extends Component {
  constructor(props){
    super(props)
    setLastUrl()
    this.state ={
      data:{
        new_search:"",
      },
      users: [],
      all: [],
      search: "",
      loading: true,
      open:false
    }
    this.handleChange = this.handleChange.bind(this);
    this.fetchUsers = this.fetchUsers.bind(this);
    this.fetch_next_page = this.fetch_next_page.bind(this);
    this.fetch_page = this.fetch_page.bind(this);
    this.fetch_prev_page = this.fetch_prev_page.bind(this);
  }

componentDidMount(){
  this.fetchUsers();
}

fetchUsers = () =>{
    const {data} = this.state
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    fetch(getConfig('getAllWallet'), requestOptions)
    .then(async response => {
    const data = await response.json();
    if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }
    if(data.success === false){
      this.setState({users: [], loading:false });
    }else{
      this.setState({users: data.data, all:data, loading:false });
    }
  })
  .catch(error => {
    if (error === "Unauthorized") {
        this.props.logout()
        }
      this.setState({loading:false });
      console.error('There was an error!', error);
  });
}

handleChange(event) {
  const { name, value } = event.target;
  const { data } = this.state;
    this.setState({ data: { ...data, [name]: value }, loading:true},()=>{
      this.fetchUsers()
    });
}

fetch_next_page = ()=>{
  const {all} = this.state
  this.setState({ loading: true});
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  fetch(all.next_page_url, requestOptions).then(async (response) =>{
    const data =await response.json();
    if(data.success === false){
      this.setState({users: [], loading:false });
    }else{
      this.setState({users: data.data, all:data, loading:false });
    }
  }).catch(error=>{
    if (error === "Unauthorized") {
      this.props.logout();
    }
  })
}

fetch_prev_page = ()=>{
  const {all} = this.state
  this.setState({ loading: true});
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  fetch(all.prev_page_url, requestOptions).then(async (response) =>{
    const data =await response.json();
    if(data.success === false){
      this.setState({users: [], loading:false });
    }else{
      this.setState({users: data.data, all:data, loading:false });
    }
  }).catch(error=>{
    if (error === "Unauthorized") {
      this.props.logout();
    }
  })
}

fetch_page = (index)=>{
  const {all} = this.state
  this.setState({ loading: true});
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  fetch(all.path+"?page="+index, requestOptions).then(async (response) =>{
    const data =await response.json();
    if(data.success === false){
      this.setState({users: [], loading:false });
    }else{
      this.setState({users: data.data, all:data, loading:false });
    }
  }).catch(error=>{
    if (error === "Unauthorized") {
      this.props.logout();
    }
  })
}

render(){
  const {theme} = this.props
  const {users, loading,data, all} = this.state
    return (
      <div style={{padding: theme.spacing(3)}}>
        <Grid container spacing={4} justify="center" >
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              style={{width:"30%"}}
              select
              label="search"
              name="new_search"
              variant="outlined"
              margin="dense"
              value={data.new_search}
              onChange={this.handleChange}>
            </TextField>
          </Grid>
        </Grid>
        <div style={{marginTop: theme.spacing(2)}}>
          <UsersTable users={users} pagination={all} type="shareholding" fetch_page={this.fetch_page} fetch_next_page={this.fetch_next_page} fetch_prev_page={this.fetch_prev_page} loading={loading} link={"wallet"}/>
        </div>
      </div>
    );
  };
}
  
function mapState(state) {
  const { savings } = state.savings;
  return { savings };
}
const actionCreators = {
  logout: adminActions.logout,
};

export default withStyles({}, { withTheme: true })(
  withRouter(connect(mapState,  actionCreators)(WalletTabTab))
);