import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Card, Button, Typography, IconButton,ButtonGroup, Toolbar, AppBar, Dialog, MenuItem, TextField, Tooltip, CircularProgress, Input, } 
from '@material-ui/core';
import {Link} from 'react-router-dom';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { Component } from "react";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { getConfig, numberFormat, setLastUrl } from "redux/config/config";
import { authHeader } from "redux/logic";
import { adminActions } from "redux/action";
import ProductDetails from "./components/ProductDetails";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import DeleteIcon from '@material-ui/icons/Delete';
import swal from "sweetalert";

class CreateOrder extends Component {
  constructor(props){
    super(props)
    setLastUrl()
    this.state = {
      first : [
        {
            product_finance_id:"",
            product_name:"",
            price:0,
            quantity:0,
            short_description:"",
            quantity_rem:0,
            initial_price:"",
            mark_up:0
        },],
        second :{
            user_id : "",
            payment_method : "",
            bank_id : "",
            bank_name : "",
            deposit_amount : "",
            payment_date : "",
            total : ""
        },
      users: [],
      products: [],
      singleNews:[],
      product:[],
      isLoading:true,
      loading:true,
      count:[],
      banks:[],
    }
    this.fetchProducts = this.fetchProducts.bind(this);
    this.handleIncrement = this.handleIncrement.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.fetchBanks = this.fetchBanks.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    const {first, second} = this.state
    let total = 0
    first.forEach(d => {
      total += Number(d.price);
    });
    let dat = {first, second:[second]};
    if(second.payment_method === "Bank"){
      if(second.deposit_amount >= total){
        console.log(dat)
        this.props.createOrder(dat)
      }else{
        swal("Deposited amount is not enough to continue this transaction(s)")
      }
    }else{
      console.log(dat)
      this.props.createOrder(dat)
    }
  }

  componentDidMount(){
    this.fetchBanks()
  }

  fetchBanks = () =>{
    const requestOptions = {
      method: 'GET',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    fetch(getConfig('getAllBanks'), requestOptions)
    .then(async response => {
    const data = await response.json();
    if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }
    console.log(data)
    this.setState({banks: data});
    })
    .catch(error => {
    if (error === "Unauthorized") {
          this.props.logout()
      }
    });
  }

  handleBankChange = (event) =>{
    event.preventDefault();
    const {second, banks} = this.state
    const {name, value} = event.target
    if(name === "bank_name"){
      banks.forEach(b => {
        if(b.bank_name === value){
          this.setState({second:{...second, [name]:value, bank_id:b.id, bank_name:b.bank_name}})
        }
      });
    }else if(name === "payment_method"){
      if(value === "Bank"){
        this.setState({second:{...second, [name]:value }})
      }else{
        this.setState({second:{...second, [name]:value, bank_id:"", bank_name:"", deposit_amount:"", payment_date:"" }})
      }
    }else{
      this.setState({second:{...second, [name]:value}})
    }
  }

  fetchProducts = (search) =>{
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(search),
    };
    fetch(getConfig('searchProduct'), requestOptions)
    .then(async response => {
    const data = await response.json();
    if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }
    if(data.success === false){
      this.setState({products: []});
    }else{
      this.setState({products: data});
    }
    console.log(data)
  })
  .catch(error => {
      if (error === "Unauthorized") {
            this.props.logout()
        }
      this.setState({loading:false, err : "internet error" });
      console.error('There was an error!', error);
    });
  }

  fetchUsers = (search) =>{
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(search),
    };
    fetch(getConfig('getAllUsersAutoComplete'), requestOptions)
    .then(async response => {
    const data = await response.json();
    if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }
    console.log(data)
    this.setState({users: data});
  })
  .catch(error => {
      if (error === "Unauthorized") {
            this.props.logout()
        }
      this.setState({loading:false, err : "internet error" });
      console.error('There was an error!', error);
    });
  }

  handleChangeUser = (event, val) =>{
    const {name, value } = event.target;
    const { second, users } = this.state;
    this.fetchUsers({term: value});
    users.forEach(user => {
      if(val === user.id +  user.first_name + " " + user.last_name){
        this.setState({second: {...second, user_id: user.id}});
      }
    });
  }

  handleIncrement = () =>{
    const {first} = this.state
    this.setState({
      first:[...first, {
          product_finance_id:"",
          product_name:"",
          price:0,
          quantity:0,
          short_description:"",
          quantity_rem:0,
          initial_price:""
      },]
    })
  }
  
  handleRemove = (id) =>{
    const {first} = this.state
    first.splice(id, 1)
    this.setState({first:first})
  }

  handleChangeProduct = (event, val, id) =>{
    const {name, value } = event.target;
    const { first, products } = this.state;
    let newArray = [...first];
    const elementsIndex = first.findIndex((element, index) => index === id )
    if(name === "quantity"){
      if(newArray[elementsIndex].quantity_rem >= value && value > 0){
        if(newArray[elementsIndex].quantity > value){
          newArray[elementsIndex] = {...newArray[elementsIndex], [name]:value, price:Number(newArray[elementsIndex].price) - Number(newArray[elementsIndex].initial_price)}
        }else{
          newArray[elementsIndex] = {...newArray[elementsIndex], [name]:value, price:Number(newArray[elementsIndex].initial_price) * value}
        }
        
      }else if(value <= 0){
        newArray[elementsIndex] = {...newArray[elementsIndex], [name]:0, price: newArray[elementsIndex].initial_price}
      }
      
    }else if(name === "short_description" || name === "price"){
      newArray[elementsIndex] = {...newArray[elementsIndex], [name]:value}
    }else{
      this.fetchProducts({term: value});
      if(products.length !== 0){
        products.forEach(prod => {
        if(val === prod.product_name){
            newArray[elementsIndex] = {...newArray[elementsIndex], product_finance_id: prod.id, quantity_rem: prod.quantity, short_description:prod.short_description, price:prod.price, initial_price:prod.price, product_name:prod.product_name}
          }
        });
      }
    }
    console.log(newArray)
    this.setState({first: newArray});
  }


render(){
 const {count, product, isLoading, products, users, first, second, banks} = this.state
  return (
    <div>
      <ValidatorForm onSubmit={this.handleSubmit} >
          <Card elevation={4} style={{margin:20, padding:10}}>
            <Typography variant="h6" color="textSecondary" className="text-center">User Data  </Typography>
            <Grid container spacing={2} >
              <Grid item lg={4} md={4} sm={6} xs={6}>
                <Autocomplete
                  freeSolo
                  id="free-solo-2-demo"
                  disableClearable
                  onChange={(event, value) => this.handleChangeUser(event, value)}
                  options={users.map((option) =>option.id + option.first_name + " " + option.last_name )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={(event, value) => this.handleChangeUser(event, value)}
                      label="Search users"
                      margin="normal"
                      variant="outlined"
                      InputProps={{ ...params.InputProps, type: 'search' }}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    helperText="Select Payment method"
                    label="Payment method"
                    name="payment_method"
                    onChange={this.handleBankChange}
                    value={second.payment_method}
                    type="text"
                    variant="outlined"
                    select>
                    <MenuItem value={"Bank"}>Bank</MenuItem>
                    <MenuItem value={"co-operative"}>co-operative</MenuItem>
                  </TextField>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={6}>
                  <TextValidator
                    fullWidth
                    margin="normal"
                    label="Total Amount"
                    helperText="Enter Total amount"
                    name="total"
                    onChange={this.handleBankChange}
                    value={second.total}
                    type="number"
                    variant="outlined"
                    validators={[ "required"]}
                  />
              </Grid>
              {second.payment_method === "Bank" &&<>
              <Grid item lg={4} md={4} sm={6} xs={6}>
                <TextValidator
                  label="Bank Name"
                  fullWidth
                  name="bank_name"
                  margin="normal"
                  variant="outlined"
                  value={second.bank_name}
                  validators={["required"]}
                  onChange={this.handleBankChange}
                  select>
                    <MenuItem value={second.bank_name}>{second.bank_name}</MenuItem>
                    {banks.map((option, index) => (
                      <MenuItem key={index} value={option.bank_name}>
                        {option.bank_name}
                      </MenuItem>
                    ))}
                  </TextValidator>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={6}>
                <TextValidator
                  fullWidth
                  margin="normal"
                  helperText="Enter amount value"
                  label=" Deposited Amount"
                  name="deposit_amount"
                  onChange={this.handleBankChange}
                  value={second.deposit_amount}
                  type="number"
                  variant="outlined"
                  validators={[ "required"]}
                />
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={6}>
                  <TextValidator
                    fullWidth
                    margin="normal"
                    helperText="Enter payment date"
                    name="payment_date"
                    onChange={this.handleBankChange}
                    value={second.payment_date}
                    type="date"
                    variant="outlined"
                    validators={[ "required"]}
                  />
              </Grid>
              </>}
          </Grid>
          <Typography variant="h6" color="textSecondary" className="text-center">Products</Typography>
            {first.map((dat, index) =>(
            <Grid container spacing={2} key={index}>
              <Grid item sm={6} xs={6} md={3} lg={3}>
                <Autocomplete
                  freeSolo
                  id="free-solo-2-demo"
                  disableClearable
                  onChange={(e, v)=>this.handleChangeProduct(e, v, index)}
                  options={products.map((option) =>option.product_name )}
                  renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={(e, v)=>this.handleChangeProduct(e, v, index)}
                    label="Search products"
                    margin="normal"
                    variant="outlined"
                    InputProps={{ ...params.InputProps, type: 'search' }}
                  />
                )}/>
              </Grid>
              <Grid item sm={6} xs={6} md={3} lg={3}>
                  <TextValidator
                    fullWidth
                    margin="normal"
                    helperText="Short Description"
                    name="short_description"
                    onChange={(e, v)=>this.handleChangeProduct(e, v, index)}
                    value={dat.short_description}
                    type="text"
                    variant="outlined"
                    validators={[ "required"]}
                  />
              </Grid>
              <Grid item sm={6} xs={6} md={3} lg={3}>
                  <TextField
                    fullWidth
                    disabled
                    margin="normal"
                    helperText="Product price"
                    name="price"
                    // onChange={(e, v)=>this.handleChangeProduct(e, v, index)}
                    value={numberFormat(Number(dat.price) + Number(dat.mark_up))}
                    type="text"
                    variant="outlined"
                    validators={[ "required"]}
                  />
              </Grid>
              <Grid item sm={5} xs={5} md={2} lg={2}>
                  <TextValidator
                    fullWidth
                    margin="normal"
                    label="Product quantity"
                    name="quantity"
                    onChange={(e, v)=>this.handleChangeProduct(e, v, index)}
                    value={dat.quantity}
                    type="number"
                    variant="outlined"
                    validators={[ "required"]}
                  />
              </Grid>
              <Grid item lg={1} md={1} sm={1} xs={1}>
              {index !== 0 &&
              <Tooltip title="remove product">
                <IconButton margin="normal" type="button" variant="contained" color="primary" onClick={()=>this.handleRemove(index)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>}
            </Grid>
          </Grid>
            ))}
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Button variant="contained" type="button" color="secondary" onClick={this.handleIncrement} >Add New</Button>
            {this.props.savings && <CircularProgress />}
            <Button variant="contained" type="submit" color="primary">Submit</Button>
          </Grid>
        </Card>
      </ValidatorForm>
    </div>
  );
}
}

const actionCreators = {
  logout: adminActions.logout,
  createOrder: adminActions.createOrder,
};

function mapState(state) {
  const { savings } = state.savings;
  return { savings };
}
export default withStyles({}, { withTheme: true })(
  withRouter(connect(mapState,  actionCreators)(CreateOrder))
);