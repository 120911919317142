import React, { Component } from 'react'
import SimpleTabs from '../components/Tabs/WalletTabsPanel'

class WalletTab extends Component {
    render() {
        return (
            <div>
              <SimpleTabs />
            </div>
        )
    }
}

  export default WalletTab;