
import { history } from '../logic';
const serverVars = {
  // baseUrl: "http://142.93.152.229/sesis/api/",
  baseUrl: "https://api.sesiscoop.com/api/",
  validateLogin:"admin/validate_login",
  adminlogin:"admin/login",
  adminsignup: "admin/signup",
  resetPass: "admin/adminResetPassword",
  recoverPassword: "admin/adminRecoverPassword",
  adminChangePassword: "admin/adminChangePassword?token=",
  disableUsers: "disableUsers/",
  enableUsers: "enableUsers/",
  getAllUsers: "getAllUsers?token=",
  getAllDashboard: "getAllDashboard?token=",
  loanDisbursed: "loanDisbursed?token=",
  getAdminShow:  "admin/adminShow?token=",
  // all regular savings
  getAllTargetSavings:"allTargetsAdmin?token=",
  adminShowAllCommission:"adminShowAllCommission?token=",
  getAllDues:"fetchDues?token=",
   // all regular savings
   getSingleUserDetails:"singleUserDetails/",
   regularSavingsTransactionsAdmin:"regularSavingsTransactionsAdmin/",
   loanRepaymentsTransactions:"loanRepaymentsTransactions/",
   saveLoanTransactionsAdmin:"saveLoanTransactionsAdmin/",
   walletTransactionsAdmin:"walletTransactionsAdmin/",
   targetTransactionsAdmin:"targetTransactionsAdmin/",

  // all save to loan savings
  getAllSaveToLoanSavings:"saveLoanAdmin?token=",
  getAllWallet:"walletAdmin?token=",
  getPosting:"get_posting?token=",
  dashboardLoans:"dashboardLoans?token=",
  
  // all regular savings
  getAllRegularSavings:"regularSavingsAdmin?token=",

  addHalalCategory:"save_halai_category/store?token=",
  updateHalalCategory:"save_halai_category/update/", // id needed
  getHalalCategory: "showHalaiCategoryAdmin?token=",
  adminAddHalal: "save_halai/store",//save halal
  updateHalalInvestment: "halai/update/", //id needed
  getHalalInvestment: "showHalaiInvestments?token=",
  getHalalCategoryName: "showHalaiCategories?token=",
  addHalalNews: "save_halai_news/store?token=",
  updateHalalNews: "save_halai_news/update/", // id needed
  getHalalNews: "show_halai_news?token=",
  getHalalNewsType: "showHalaiType?token=",
  regularSavingsSearchAdmin: "regularSavingsSearchAdmin/B2?token=",
  addMarketCategory: "save_category/store?token=",
  showSingleCategory: "showSingleCategory/",
  showSingleMarketNews: "showSingleMarketNews/",
  showSingleHalaiNews: "showSingleHalaiNews/",
  showSingleHalaiCategory: "showSingleHalaiCategory/",
  deleteCategory: "deleteCategory/",
  deleteMarketNews: "deleteMarketNews/",
  deleteTargetCommission: "deleteTargetCommission/",
  deleteHalaiNews: "deleteHalaiNews/",
  reversePosting: "reversePosting/",
  deleteHalaiCategory: "deleteHalaiCategory/",
  updateMarketCategory:"save_category/update/", // id needed
  getMarketCategory: "showMarketCategoryAdmin?token=",
  adminAddMarket: "save_investment/store?token=", // save market
  updateMarketView: "market_place/update/", // id needed
  showMarketInvestments: "showMarketInvestments?token=",
  showHalaiInvestments: "showHalaiInvestments?token=",
  getMarketView: "getMarketView/",
  getMarketCategoryName:  "showMarketCategoryAdmin?token=",
  getMarketCategoryType: "showMarketCategories?token=",
  getHalalCategoryType: "showHalaiCategories?token=",
  addMarketNews: "save_news/store?token=",
  addAdmin: "admin/addAdmin?token=",
  getSingleAdmin: "admin/fetchSingleAdmin/",
  updateMarketNews: "save_news/update/", //id needed
  updateAdmin: "admin/updateAdmin/", //id needed
  disableAdmin: "admin/adminDisabled/", //id needed
  enableAdmin: "admin/adminEnabled/", //id needed
  deleteUser:"deleteUser/",
  transactions: "transaction?token=",
  export_all_transactions: "export_all_transactions?token=",
  transaction_export: "transaction_export?token=",
  savingsData: "adminDashboardPieChart?token=",
  histogramData: "adminDashboardhistogram?token=",
  getMarketNews: "show_market_news?token=",
  getMarketNewsType: "showMarketType?token=",
  modifyTargetCommission:'modifyTargetCommission/',
  EditDues:"updateDues/",
  AddPaymentOptions: "payment_options/",
  UpdatePaymentOptions: "payment_options/",
  singleTargetCommission: "singleTargetCommission/",
  addDues:"createDues?token=",
  addTargetCommission: "addTargetCommission?token=",
  getLoansForApproval: "adminLoansForApproval?token=",
  getCompletedLoans:"adminViewCompletedLoans?token=",
  getAllGuarantor:"adminViewAllGuarantorsPerLoan/",
  getAllMarketInvestor:"allInvestmentsAdmin?token=",
  getAllHalalInvestor:"allHalaiInvestmentsAdmin?token=",
  getAllApprovedLoan:"adminAllUsersDatAvCollectedLoans?token=",
  adminUpdateHalal:"halai/update/",
  adminUpdateMarket:"market_place/update/",
  adminApproveLoanz:"adminApproveLoanz/",
  disburstLoan:"admin_disburse_loans/",
  disburseBusinessLoan:"admin_disburse_business_loans/",
  singleHalalInvestment:"singleHalalInvestment/",
  singleMarketInvestment:"singleMarketInvestment/",
  getAllUsersAutoComplete:"searchUsers",
  getUsersPackage:"fetchUserPackages",
  saveMultipleAcct:"saveMultipleTransactions?token=",
  saveMultipleDues:"saveMultipleDues?token=",
  addBankDetails:"adminAddBankDetails?token=",
  getAllBanksDropDown:"bankInfoSelectDropdown?token=",
  addNewBank:"adminAddBankDetails?token=",
  getSingleBank:"adminSingleBankDetails/",
  updateBank:"adminUpdateBankDetails/",
  getAllBanks:"adminDisplayAllBanks?token=",  

  paymentOption:"display_payment_options?token=",
  getUsersLoanGroup:"adminFetchUserGroupName/",
  createUserLoan:"adminCreateUserLoan?token=",
  addUser:"auth/adminSignupUsers",
  createTargetAcct:"adminCreateTargetPlan?token=",
  allOrders:"adminAllOrders?token=",
  allProduct:"allFinanceProducts?token=",
  allOrderProduct:"adminAllOrdersProducts/",
  approveOrder:"adminApproveOrders/",
  declineOrder:"adminDeclineOrders/",
  deliverOrder:"adminDeliverOrders/",
  allProductCategory:"allFinanceCategories?token=",
  addProductCategory:"insertFinanceCategories?token=",
  deleteProductCategory:"deleteFinanceCategories/",
  allOrderRequest:"adminFetchAllProductsRequest?token=",
  addPrice:"addPrice/",
  getProductCategoryDropdown:"productFinanceCategories?token=",
  addProduct:"adminAddProducts?token=",
  deleteProduct:"deleteProductFinance",
  editProduct:"adminUpdateProducts",
  addCart:"adminAddToCart/",
  searchProduct:'searchProducts?token=',
  singleProduct:'extendProductSearch/',
  createOrder: 'admin_makes_order?token=',
  getWithdrawal:"view_withdrawal_lists?token=",
  makeWithdrawal:"admin_make_bank_transfers?token=",

  approvedBusiness:'admin_view_approved_request?token=',
  pendingBusinessRequest:'admin_pending_request?token=',
  addBusinessPrice:'admin_adds_price/',
  pendingBusinessApproval:'admin_view_pending_approval?token=',
  approveBusinessRequest:'admin_approves_request/',
  cancelBusinessRequest:'admin_cancels_request/',
  ongoingBusiness:'admin_ongoing_loans?token=',
  completedBusiness:'admin_completed_loans?token=',
  ongoingBusinessDetails:'admin_ongoing_transactions/',
  completedBusinessDetails:'admin_completed_transactions/',
  activitiesLog:"show_activity?token=",
  deleteActivities:"delete_activity_logs?token=",
  getDownPayment:"admin/down_payment?token=",
  accountSettings:"admin/add_accounting_settings?token=",
  getGLCode: "get_gl_list?token=",
  getAllAccountingSettings:"admin/display_accounting_settings?token=",
  createRole:"admin/adminAddRoles?token=",
  updateRole:"admin/update_admins_roles/",
  getAllRole:"admin/adminListAllRole?token=",
  allPermissions:"/admin/lists_permissions?token=",
  activateOnline:"auth/activateOnlineBanking/",
  activateUser:"activateMemberStatus/",
};

export const numberFormat = (value) =>
  new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(value);

export const payID = () => {
  return "pk_test_d96f199a0651f1162b81c56256d5842372b845f2";
};

export const setLastUrl = () =>{
  var lasturl = window.location.href;
  var pathname = new URL(lasturl).pathname;
  localStorage.setItem("lasturl", pathname);
}

export const checkLastUrl=()=>{
  let pathname = localStorage.getItem("lasturl");
  let user = JSON.parse(localStorage.getItem("admin"));
  if (pathname == null || pathname == "") {
    history.push({
      pathname: "/dashboard"
    });
    } else{
      history.push({
        pathname: pathname
      });
    }
}

export const checkToken = ()=>{
  let pathname = localStorage.getItem("lasturl");
  let token =  localStorage.getItem('token');
    if (token == null || token == "") {
      history.push({
        pathname: "/sign-in"
      });
      }else{
        history.push({
          pathname: pathname
        });
      }
}

// export const checkToken = ()=>{
//   let token =  JSON.parse(localStorage.getItem('admin'));
//     if (token === null) {
//         history.push('/sign-in');
//       }
// }

export function getConfig(apiName) {
  let user = JSON.parse(localStorage.getItem("admin"));
  if ((apiName !== 'adminlogin') && user === null) {
	if(apiName !== 'validateLogin'){
      if(apiName !== "adminsignup"){
        if(apiName !== "recoverpass"){
          history.push('/sign-in');
          return
      	}
      }
	}
  }
  switch (apiName) {
    case "validateLogin":
      return serverVars.baseUrl + serverVars.validateLogin;
    case "adminlogin":
      return serverVars.baseUrl + serverVars.adminlogin;
    case "recoverpass":
      return serverVars.baseUrl + serverVars.recoverpass;
    case "resetPass":
      return serverVars.baseUrl + serverVars.resetPass +user.token;      
    case "recoverPassword":
      return serverVars.baseUrl + serverVars.recoverPassword;
    case "adminChangePassword":
      return serverVars.baseUrl + serverVars.adminChangePassword +user.token;
    case "adminsignup":
      return serverVars.baseUrl + serverVars.adminsignup;
      // all users
    case "getAllRegularSavings":
      return serverVars.baseUrl + serverVars.getAllRegularSavings;
    case "getAllSaveToLoanSavings":
      return serverVars.baseUrl + serverVars.getAllSaveToLoanSavings;
    case "getAllWallet":
      return serverVars.baseUrl + serverVars.getAllWallet;
      case "getPosting":
        return serverVars.baseUrl + serverVars.getPosting;
    case "dashboardLoans":
      return serverVars.baseUrl + serverVars.dashboardLoans;
    case "getAllTargetSavings":
      return serverVars.baseUrl + serverVars.getAllTargetSavings;    
    case "getAllDues":
      return serverVars.baseUrl + serverVars.getAllDues;
    case "adminShowAllCommission":
      return serverVars.baseUrl + serverVars.adminShowAllCommission;
    case "getSingleUserDetails":
      return serverVars.baseUrl + serverVars.getSingleUserDetails;
    case "regularSavingsTransactionsAdmin":
      return serverVars.baseUrl + serverVars.regularSavingsTransactionsAdmin;
    case "loanRepaymentsTransactions":
      return serverVars.baseUrl + serverVars.loanRepaymentsTransactions;
    case "saveLoanTransactionsAdmin":
      return serverVars.baseUrl + serverVars.saveLoanTransactionsAdmin;
    case "walletTransactionsAdmin":
      return serverVars.baseUrl + serverVars.walletTransactionsAdmin;
    case "targetTransactionsAdmin":
      return serverVars.baseUrl + serverVars.targetTransactionsAdmin;
    case "getAllUsers":
      return serverVars.baseUrl + serverVars.getAllUsers;
    case "getAdminShow":
      return serverVars.baseUrl + serverVars.getAdminShow;
    case "getAllDashboard":
      return serverVars.baseUrl + serverVars.getAllDashboard +user.token;
    case "loanDisbursed":
      return serverVars.baseUrl + serverVars.loanDisbursed +user.token;
    // Halal api
    case "addHalalCategory":
      return serverVars.baseUrl + serverVars.addHalalCategory +user.token;
    case "adminAddHalal":
      return serverVars.baseUrl + serverVars.adminAddHalal;
    case "updateHalalInvestment":
      return serverVars.baseUrl + serverVars.updateHalalInvestment;
    case "getHalalCategory":
      return serverVars.baseUrl + serverVars.getHalalCategory +user.token;
    case "getHalalInvestment":
      return serverVars.baseUrl + serverVars.getHalalInvestment +user.token;
    case "getHalalCategoryName":
      return serverVars.baseUrl + serverVars.getHalalCategoryNam +user.token;
    case "addHalalNews":
      return serverVars.baseUrl + serverVars.addHalalNews +user.token;
    case "updateHalalNews":
      return serverVars.baseUrl + serverVars.updateHalalNews;
    case "getHalalNews":
      return serverVars.baseUrl + serverVars.getHalalNews +user.token;
    case "getHalalNewsType":
      return serverVars.baseUrl + serverVars.getHalalNewsType +user.token;
      case "regularSavingsSearchAdmin":
        return serverVars.baseUrl + serverVars.regularSavingsSearchAdmin +user.token;
    case "getWithdrawal":
      return serverVars.baseUrl + serverVars.getWithdrawal;
    case "makeWithdrawal":
      return serverVars.baseUrl + serverVars.makeWithdrawal;
  
      // market api 
    case "addMarketCategory":
      return serverVars.baseUrl + serverVars.addMarketCategory +user.token;
    case "updateMarketCategory":
      return serverVars.baseUrl + serverVars.updateMarketCategory;
    case "updateHalalCategory":
      return serverVars.baseUrl + serverVars.updateHalalCategory;
    case "showSingleCategory":
      return serverVars.baseUrl + serverVars.showSingleCategory;
    case "showSingleMarketNews":
      return serverVars.baseUrl + serverVars.showSingleMarketNews;
    case "showSingleHalaiNews":
      return serverVars.baseUrl + serverVars.showSingleHalaiNews;
    case "showSingleHalaiCategory":
      return serverVars.baseUrl + serverVars.showSingleHalaiCategory;
    case "disableUsers":
      return serverVars.baseUrl + serverVars.disableUsers;
    case "enableUsers":
      return serverVars.baseUrl + serverVars.enableUsers;
    case "deleteCategory":
      return serverVars.baseUrl + serverVars.deleteCategory;
    case "deleteHalaiCategory":
      return serverVars.baseUrl + serverVars.deleteHalaiCategory; 
    case "deleteMarketNews":
      return serverVars.baseUrl + serverVars.deleteMarketNews; // 
    case "deleteHalaiNews":
      return serverVars.baseUrl + serverVars.deleteHalaiNews;      
    case "reversePosting":
      return serverVars.baseUrl + serverVars.reversePosting;  
    case "deleteTargetCommission":
      return serverVars.baseUrl + serverVars.deleteTargetCommission;  
    case "adminAddMarket":
      return serverVars.baseUrl + serverVars.adminAddMarket +user.token;
    case "updateMarketView":
      return serverVars.baseUrl + serverVars.updateMarketView;
    case "getMarketCategory":
      return serverVars.baseUrl + serverVars.getMarketCategory +user.token;
    case "getMarketView":
      return serverVars.baseUrl + serverVars.getMarketView +user.token;
    case "showMarketInvestments":
      return serverVars.baseUrl + serverVars.showMarketInvestments +user.token;
    case "showHalaiInvestments":
      return serverVars.baseUrl + serverVars.showHalaiInvestments +user.token;
    case "getMarketCategoryName":
      return serverVars.baseUrl + serverVars.getMarketCategoryName +user.token;
    case "addMarketNews":
      return serverVars.baseUrl + serverVars.addMarketNews +user.token;
    case "addAdmin":
      return serverVars.baseUrl + serverVars.addAdmin +user.token;
    case "addTargetCommission":
      return serverVars.baseUrl + serverVars.addTargetCommission +user.token;
    case "addDues":
      return serverVars.baseUrl + serverVars.addDues +user.token;
    case "updateMarketNews":
      return serverVars.baseUrl + serverVars.updateMarketNews;
    case "updateAdmin":
      return serverVars.baseUrl + serverVars.updateAdmin;
    case "disableAdmin":
      return serverVars.baseUrl + serverVars.disableAdmin;
    case "enableAdmin":
      return serverVars.baseUrl + serverVars.enableAdmin;
    case "deleteUser":
      return serverVars.baseUrl + serverVars.deleteUser;
    case "transactions":
      return serverVars.baseUrl + serverVars.transactions + user.token;
    case "export_all_transactions":
      return serverVars.baseUrl + serverVars.export_all_transactions + user.token;
    case "transaction_export":
      return serverVars.baseUrl + serverVars.transaction_export + user.token;
    case "savingsData":
      return serverVars.baseUrl + serverVars.savingsData + user.token;
    case "histogramData":
      return serverVars.baseUrl + serverVars.histogramData + user.token;
    case "EditDues":
      return serverVars.baseUrl + serverVars.EditDues;
    case "modifyTargetCommission":
      return serverVars.baseUrl + serverVars.modifyTargetCommission;
    case "getMarketNews":
      return serverVars.baseUrl + serverVars.getMarketNews +user.token;
    case "getMarketNewsType":
      return serverVars.baseUrl + serverVars.getMarketNewsType +user.token;
    case "getSingleAdmin":
      return serverVars.baseUrl + serverVars.getSingleAdmin;
    // case "getSingleAdmin":
    //   return serverVars.baseUrl + serverVars.getSingleAdmin +user.token;
    case "singleTargetCommission":
      return serverVars.baseUrl + serverVars.singleTargetCommission;
    case "AddPaymentOptions":
      return serverVars.baseUrl + serverVars.AddPaymentOptions;
    case "UpdatePaymentOptions":
      return serverVars.baseUrl + serverVars.UpdatePaymentOptions;
    case "getLoansForApproval":
      return serverVars.baseUrl + serverVars.getLoansForApproval;
    case "getCompletedLoans":
      return serverVars.baseUrl + serverVars.getCompletedLoans;
    case "getAllGuarantor":
      return serverVars.baseUrl + serverVars.getAllGuarantor;
    case "getAllMarketInvestor":
      return serverVars.baseUrl + serverVars.getAllMarketInvestor + user.token;
    case "getAllHalalInvestor":
      return serverVars.baseUrl + serverVars.getAllHalalInvestor + user.token;
    case "getMarketCategoryType":
      return serverVars.baseUrl + serverVars.getMarketCategoryType + user.token;
    case "getHalalCategoryType":
      return serverVars.baseUrl + serverVars.getHalalCategoryType + user.token;
    case "getAllApprovedLoan":
      return serverVars.baseUrl + serverVars.getAllApprovedLoan + user.token;
    case "adminUpdateHalal":
      return serverVars.baseUrl + serverVars.adminUpdateHalal;
    case "adminUpdateMarket":
      return serverVars.baseUrl + serverVars.adminUpdateMarket;
    case "adminApproveLoanz":
      return serverVars.baseUrl + serverVars.adminApproveLoanz;
    case "disburstLoan":
      return serverVars.baseUrl + serverVars.disburstLoan;
    case "singleHalalInvestment":
      return serverVars.baseUrl + serverVars.singleHalalInvestment;
    case "singleMarketInvestment":
      return serverVars.baseUrl + serverVars.singleMarketInvestment;
    case "getAllUsersAutoComplete":
      return serverVars.baseUrl + serverVars.getAllUsersAutoComplete;
    case "getUsersPackage":
      return serverVars.baseUrl + serverVars.getUsersPackage;
    case "saveMultipleDues":
      return serverVars.baseUrl + serverVars.saveMultipleDues + user.token;
    case "saveMultipleAcct":
      return serverVars.baseUrl + serverVars.saveMultipleAcct + user.token;
    case "addBankDetails":
      return serverVars.baseUrl + serverVars.addBankDetails + user.token;
    case "getAllBanksDropDown":
      return serverVars.baseUrl + serverVars.getAllBanksDropDown + user.token;
    case "addNewBank":
      return serverVars.baseUrl + serverVars.addNewBank + user.token;
    case "getAllBanks":
      return serverVars.baseUrl + serverVars.getAllBanks + user.token;
    case "paymentOption":
      return serverVars.baseUrl + serverVars.paymentOption + user.token;
    case "getSingleBank":
      return serverVars.baseUrl + serverVars.getSingleBank;
    case "updateBank":
      return serverVars.baseUrl + serverVars.updateBank;
    case "getUsersLoanGroup":
      return serverVars.baseUrl + serverVars.getUsersLoanGroup;
    case "createUserLoan":
      return serverVars.baseUrl + serverVars.createUserLoan + user.token;
    case "createTargetAcct":
      return serverVars.baseUrl + serverVars.createTargetAcct + user.token;
    case "allOrders":
      return serverVars.baseUrl + serverVars.allOrders + user.token;
    case "allProductCategory":
      return serverVars.baseUrl + serverVars.allProductCategory + user.token;
    case "deleteProductCategory":
      return serverVars.baseUrl + serverVars.deleteProductCategory;
    case "addProductCategory":
      return serverVars.baseUrl + serverVars.addProductCategory + user.token;
    case "allProduct":
      return serverVars.baseUrl + serverVars.allProduct + user.token;
    case "allOrderProduct":
      return serverVars.baseUrl + serverVars.allOrderProduct;
    case "approveOrder":
      return serverVars.baseUrl + serverVars.approveOrder;
    case "declineOrder":
      return serverVars.baseUrl + serverVars.declineOrder;
    case "deliverOrder":
      return serverVars.baseUrl + serverVars.deliverOrder;
    case "addUser":
      return serverVars.baseUrl + serverVars.addUser;
    case "allOrderRequest":
      return serverVars.baseUrl + serverVars.allOrderRequest+ user.token;
    case "addPrice":
      return serverVars.baseUrl + serverVars.addPrice;
    case "getProductCategoryDropdown":
      return serverVars.baseUrl + serverVars.getProductCategoryDropdown+ user.token;
    case "addProduct":
      return serverVars.baseUrl + serverVars.addProduct+ user.token;
    case "editProduct":
      return serverVars.baseUrl + serverVars.editProduct;
    case "deleteProduct":
      return serverVars.baseUrl + serverVars.deleteProduct;
    case "singleProduct":
      return serverVars.baseUrl + serverVars.singleProduct;
    case "searchProduct":
      return serverVars.baseUrl + serverVars.searchProduct+ user.token;
    case "createOrder":
      return serverVars.baseUrl + serverVars.createOrder+ user.token;
    case "addCart":
      return serverVars.baseUrl + serverVars.addCart;

    case "approvedBusiness":
      return serverVars.baseUrl + serverVars.approvedBusiness+ user.token; //get
    case "pendingBusinessRequest":
      return serverVars.baseUrl + serverVars.pendingBusinessRequest+ user.token; //get
    case "addBusinessPrice":
      return serverVars.baseUrl + serverVars.addBusinessPrice; //post
    case "pendingBusinessApproval":
      return serverVars.baseUrl + serverVars.pendingBusinessApproval+ user.token; //get
    case "approveBusinessRequest":
      return serverVars.baseUrl + serverVars.approveBusinessRequest; //post
    case "cancelBusinessRequest":
      return serverVars.baseUrl + serverVars.cancelBusinessRequest; //post
    case "ongoingBusiness":
      return serverVars.baseUrl + serverVars.ongoingBusiness + user.token; //get
    case "completedBusiness":
      return serverVars.baseUrl + serverVars.completedBusiness + user.token; //get
    case "ongoingBusinessDetails":
      return serverVars.baseUrl + serverVars.ongoingBusinessDetails; //get
    case "completedBusinessDetails":
      return serverVars.baseUrl + serverVars.completedBusinessDetails; //get
    case "activitiesLog":
      return serverVars.baseUrl + serverVars.activitiesLog+ user.token; //get
    case "deleteActivities":
      return serverVars.baseUrl + serverVars.deleteActivities+ user.token;
    case "disburseBusinessLoan":
      return serverVars.baseUrl + serverVars.disburseBusinessLoan;
    case "getDownPayment":
      return serverVars.baseUrl + serverVars.getDownPayment+ user.token;
    case "accountSettings":
      return serverVars.baseUrl + serverVars.accountSettings+ user.token;
    case "getAllAccountingSettings":
      return serverVars.baseUrl + serverVars.getAllAccountingSettings+ user.token;
    case "getGLCode":
      return serverVars.baseUrl + serverVars.getGLCode+ user.token;
    case "createRole":
      return serverVars.baseUrl + serverVars.createRole+ user.token;
    case "updateRole":
      return serverVars.baseUrl + serverVars.updateRole;
    case "getAllRole":
      return serverVars.baseUrl + serverVars.getAllRole+ user.token;
    case "allPermissions":
      return serverVars.baseUrl + serverVars.allPermissions+ user.token;
    case "activateOnline":
      return serverVars.baseUrl + serverVars.activateOnline;
    case "activateUser":
      return serverVars.baseUrl + serverVars.activateUser;
    default:
      return null;
  }
}
